import React, { useEffect, useState, useRef } from "react";
import { Box, Spinner, Heading ,useTheme, } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import ChartJS from "../../../tools/ChartSetup";
import { generateColorPalette } from "../../../tools/generateColorPalette";
import gradient from "chartjs-plugin-gradient";

const colorPalette = generateColorPalette("#000046", "#1CB5E0", 8);

const Chart2 = ({ supplier, retailer }) => {
  const theme = useTheme();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_sales/chart2`, {
          supplier,
          retailer,
          userid: 1,
        });
        const data = res.data.data;

        const chartData = {
          labels: data.map((item) => item.region),
          datasets: [
            {
              label: "Value",
              data: data.map((item) => item.value),
              backgroundColor: data.map((_, index) => {
                const ctx = chartRef.current?.ctx;
                if (ctx) {
                  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                  gradient.addColorStop(0, colorPalette[index % colorPalette.length]);
                  gradient.addColorStop(1, "#ffffff");
                  return gradient;
                }
                return colorPalette[index % colorPalette.length];
              }),
              borderColor:  "rgba(219,219,219, 1)",
              borderWidth: 2,
            },
          ],
        };
        setChartData(chartData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  if (loading) return <Spinner />;

  return (
    <Box height="30vh">
      <Heading size="md" textAlign="center" mb={4}  bg={theme.colors.primary2} color={"white"}  p={1}>
        by Region
      </Heading>
      {chartData && (
        <Bar
          ref={chartRef}
          data={chartData}
          options={{
            indexAxis: "y", // This makes the chart horizontal
            maintainAspectRatio: false,
            layout: {
              padding: {
                right: 90, // Adjust this value as needed to prevent cutoff
              },
            },
            plugins: {
              legend: {
                display: false, // Hide the legend
              },
              datalabels: {
                display: true,
                align: "right",
                anchor: "end",
                color: "#000",
                formatter: function (value) {
                  if (typeof value === "number") {
                    return `R${value.toLocaleString()}`;
                  }
                  return value;
                },
              },
              filler: { propagate: false },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Value (R)",
                },
                grid: {
                  display: false, // Remove vertical grid lines
                },
                ticks: {
                  maxRotation: 0, // Prevent label cutoff
                  autoSkip: false, // Prevent label cutoff
                },
              },
              y: {
                title: {
                  display: false,
                  text: "Region",
                },
                grid: {
                  display: false, // Remove vertical grid lines
                },
              },
            },
          }}
        />
      )}
    </Box>
  );
};

export default Chart2;
