import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const PageAccessContext = createContext();

export const usePageAccess = () => useContext(PageAccessContext);

export const PageAccessProvider = ({ children }) => {
  const [pageBtns, setPageBtns] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPageAccess = async (userid) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/get--user-access`, { userid });
      const { pageBtns } = response.data.data[0];
      console.log({pageBtns});
      setPageBtns(pageBtns);
    } catch (error) {
      console.error('Error fetching page access', error);
    } finally {
      setLoading(false);
    }
  };

  const resetContext = () => {
    setPageBtns([]);
    setLoading(false);
  };

  return (
    <PageAccessContext.Provider value={{ pageBtns, fetchPageAccess, loading,resetContext }}>
      {children}
    </PageAccessContext.Provider>
  );
};
