import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Input, Table, Thead, Tbody, Tr, Th, Td, Button, Switch, useToast, Flex, Text, Stack } from "@chakra-ui/react";
import { FaCheck, FaTimes, FaEdit, FaPlus } from "react-icons/fa";

const Users = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const handleAddUser = () => {
    navigate("/addUsers");
  };

  const handleAddSupplier = () => {
    navigate("/addSupplier");
  };

  const handleEditUser = async (userid) => {
    try {
          // https://spearbi.co.za/backend/reports/api/v1/admin/
    // http://localhost:3113/reports/api/v1/admin/
    console.log(userid);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-userType`, {
        userid,
      });

      console.log(response);
      console.log(response.data);
      const { data, message, description } = response.data;
      const { is_supplier, is_user } = data;
      if (is_supplier) {
        navigate(`/editSupplier/${userid}`);
      } else if (is_user) {
        navigate(`/editUsers/${userid}`);
      } else {
        console.log(is_supplier, is_user);
        toast({
          title: "Failed to Verify User",
          description: "Technical error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed to Verify User",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderBooleanIcon = (value) => {
    return value ? <FaCheck color="green" /> : <FaTimes color="red" />;
  };

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
            // https://spearbi.co.za/backend/reports/api/v1/admin/
    // http://localhost:3113/reports/api/v1/admin/
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-users`, {});
        const { users, message, description } = response.data;
        toast({
          title: message,
          description: description,
          status: "success",
          duration: 500,
          isClosable: true,
        });
        setUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast({
          title: "Failed to load users",
          description: "Technical error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchUsers();
  }, []);

  const [searchTerm, setSearchTerm] = useState({ fname: "", username: "" });
  const [filters, setFilters] = useState({ activeyn: false });

  const filteredData = users.filter((row) => {
    if (filters.activeyn && !row.activeyn) return false;
    const fnameMatch = row.fname.toLowerCase().includes(searchTerm.fname.toLowerCase());
    const usernameMatch = row.username.toLowerCase().includes(searchTerm.username.toLowerCase());
    return fnameMatch && usernameMatch;
  });

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchTerm((prevSearchTerm) => ({
      ...prevSearchTerm,
      [name]: value,
    }));
  };

  const handleFilterToggle = (filter) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    }).format(date);
  };

  return (
    <Box overflowX="auto" mt={16}>
      <Flex justify="space-between" mb={4}>
        <Flex align="center">
          <Stack direction="row" spacing={4}>
            <Button leftIcon={<FaPlus />} colorScheme="blue" variant="solid" onClick={handleAddUser}>
              Add User
            </Button>
            <Button leftIcon={<FaPlus />} colorScheme="purple" variant="outline" onClick={handleAddSupplier}>
              Add Supplier
            </Button>
          </Stack>
        </Flex>
        <Text>Total Users: {filteredData.length}</Text>
      </Flex>
      <Box maxHeight="700px" overflowY="auto">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>user ID</Th>
              <Th>Names</Th>
              <Th>Username</Th>
              <Th>password</Th>
              <Th>date created</Th>
              <Th>active</Th>
              <Th>Edit</Th>
            </Tr>
            <Tr>
              <Th></Th>
              <Th>
                <Input placeholder="Search by name" name="fname" value={searchTerm.fname} onChange={handleSearch} />
              </Th>
              <Th>
                <Input placeholder="Search by username" name="username" value={searchTerm.username} onChange={handleSearch} />
              </Th>
              <Th></Th>
              <Th></Th>
              <Th>
                <Switch onChange={() => handleFilterToggle("activeyn")} isChecked={filters.activeyn} />
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((row) => (
              <Tr key={row.id}>
                <Td>{row.userid}</Td>
                <Td>{row.fname}</Td>
                <Td>{row.username}</Td>
                <Td>{row.userpass}</Td>
                <Td>{formatDate(row.createddate)}</Td>
                <Td>{renderBooleanIcon(row.activeyn)}</Td>
                <Td>
                  <Button onClick={() => handleEditUser(row.userid)} leftIcon={<FaEdit />}>
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Users;
