import React from "react";
import { Button, ButtonGroup,  Flex, Stack, useTheme } from "@chakra-ui/react";
import { FaShoppingCart, FaClipboardList, FaMedkit, FaRegChartBar, FaChartPie, FaInfoCircle, FaDatabase } from "react-icons/fa";

import { useSupplierRetailer } from "../wrappers/SupplierRetailerContext";

const RetailerNavigation = () => {
  const theme = useTheme();
  const { selectedRetailer, setSelectedRetailer, navBtns } = useSupplierRetailer(); 

  const handleRetailerChange = (retailer) => {
    setSelectedRetailer(retailer);
  };


  const iconMap = {
    PNP: <FaShoppingCart />,
    CHK: <FaClipboardList />,
    DIS: <FaMedkit />,
    MAK: <FaRegChartBar />,
    CLI: <FaChartPie />,
    SUMMARY: <FaInfoCircle />,
    DATA: <FaDatabase />,
  };

  return (
    <Flex justify="space-between" mb={4} position="fixed" top="0" pt={2} bg="white" zIndex="1000" width="calc(100% - 170px)">
      <Flex align="center">
        <Stack direction="row" spacing={4}>
          <ButtonGroup spacing="4" mb="4">
            {navBtns.map((btn) => (
              <Button
                key={btn.id}
                leftIcon={iconMap[btn.value]}
                onClick={() => handleRetailerChange(btn.value)}
                bg={selectedRetailer === btn.value ? theme.colors.primary1 : "gray.200"}
                _hover={{
                  bg: selectedRetailer === btn.value ? theme.colors.primary2 : "gray.300",
                }}
                color={selectedRetailer === btn.value ? theme.colors.textActive : theme.colors.textInactive}
              >
                {btn.text}
              </Button>
            ))}
          </ButtonGroup>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default RetailerNavigation;
