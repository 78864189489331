import React from "react";
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import { FaStoreAlt, FaUsers, FaSignOutAlt, FaFileImport } from "react-icons/fa";
import { Flex, Box, Text, Button, Image, useTheme, Link } from "@chakra-ui/react";

import Users from "./pages/users/Users";
import ViewUsers from "./pages/users/ViewUsers";
import AddUsers from "./pages/users/AddUsers";
import AddSupplier from "./pages/users/AddSupplier";
import EditUsers from "./pages/users/EditUsers";
import EditSupplier from "./pages/users/EditSupplier";

import Sales from "./pages/sales/Sales";

import Imports from "./pages/imports/Imports";

import Signin from "./pages/login/Signin";

import { useAuthentication } from "./wrappers/UseAuthentication";
import RetailerNavigation from "./components/RetailerNavigation";
import SupplierNavigation from "./components/SupplierNavigation";
import { useSupplierRetailer } from "./wrappers/SupplierRetailerContext";
import { usePageAccess } from "./wrappers/PageAccessContext";

const Layout = () => {
  const { isAuthenticated, signOut } = useAuthentication();
  const { navBtns, supplierSelect, loading } = useSupplierRetailer();
  const { pageBtns, loading: pageLoading } = usePageAccess();
  const theme = useTheme();
  const location = useLocation();

  const linkStyles = {
    marginRight: "20px",
    textDecoration: "none",
    color: "black",
    _hover: {
      color: "red",
      textDecoration: "none",
    },
  };

  const getLinkStyles = (path) => ({
    color: location.pathname === path ? "white" : "black",
    _hover: {
      color: theme.colors.secondary2,
    },
  });
  /*
        <Box display="flex" alignItems="center" mr={4}>
        <Image src="/spearicon.jpg" alt="Logo" height="60px" maxWidth="200px" objectFit="contain" />
      </Box>
  */
  return (
    <Flex minHeight="100vh" overflow="hidden">
      <Flex as="nav" direction="column" bgGradient="linear(to-b, primary1, primary1)" color="white" width="150px" height="100vh" position="fixed">
        <Box display="flex" alignItems="center">
          <Image src="/spearicon.jpg" alt="Logo" objectFit="cover" />
        </Box>
        {isAuthenticated && !pageLoading && pageBtns.length > 0 && (
          <>
            { supplierSelect.length > 0 && <SupplierNavigation />}

            {pageBtns.some((page) => page.value === "Sales") && (
              <Link as={NavLink} to="/sales" sx={{ ...linkStyles, ...getLinkStyles("/sales") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaStoreAlt />
                  <Text ml={2}>Sales</Text>
                </Box>
              </Link>
            )}
            {pageBtns.some((page) => page.value === "Users") && (
              <Link as={NavLink} to="/users" sx={{ ...linkStyles, ...getLinkStyles("/users") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaUsers />
                  <Text ml={2}>Users</Text>
                </Box>
              </Link>
            )}
            {pageBtns.some((page) => page.value === "Imports") && (
              <Link as={NavLink} to="/imports" sx={{ ...linkStyles, ...getLinkStyles("/imports") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaFileImport />
                  <Text ml={2}>Imports</Text>
                </Box>
              </Link>
            )}

            <Button leftIcon={<FaSignOutAlt />} onClick={signOut} variant="outline" bg={theme.colors.primary1} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="white" position="fixed" bottom="10px" left="10px" zIndex="1000">
              Logout
            </Button>
          </>
        )}
        {!isAuthenticated && (
          <NavLink to="/signin" style={{ marginRight: "20px" }}>
            <Text>Sign In</Text>
          </NavLink>
        )}
      </Flex>

      <Flex direction="column" flex="1" overflow="auto" ml="150px">
        <Box p={4}>
          {isAuthenticated && !loading && navBtns.length > 0  && <RetailerNavigation />}
          <Routes>
            <Route path="/signin" element={<Signin />} />
            {isAuthenticated && (
              <>
                <Route path="/sales" element={<Sales />} />
                <Route path="/imports" element={<Imports />} />
                <Route path="/users" element={<Users />} />
                <Route path="/viewUsers" element={<ViewUsers />} />
                <Route path="/addUsers" element={<AddUsers />} />
                <Route path="/addSupplier" element={<AddSupplier />} />
                <Route path="/editUsers/:userid" element={<EditUsers />} />
                <Route path="/editSupplier/:userid" element={<EditSupplier />} />
              </>
            )}
            <Route path="/" element={<Signin />} />
          </Routes>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;
