import React from 'react';
import ChakraWrapper from './wrappers/ChakraWrapper'; 
import Layout from './_layout';
import { AuthProvider } from './wrappers/UseAuthentication';
import { SupplierRetailerProvider } from './wrappers/SupplierRetailerContext';
import { PageAccessProvider } from './wrappers/PageAccessContext';

function App() {
  return (
    <ChakraWrapper>
      <SupplierRetailerProvider>
      <PageAccessProvider>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </PageAccessProvider>
      </SupplierRetailerProvider>
    </ChakraWrapper>
  );
}
/*
 <ChakraWrapper>
      <SupplierRetailerProvider>
      <PageAccessProvider>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </PageAccessProvider>
      </SupplierRetailerProvider>
    </ChakraWrapper>
*/
export default App;