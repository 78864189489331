import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FormControl, FormLabel, Input, Button, Stack, Link, useToast, useTheme } from "@chakra-ui/react";
import { useAuthentication } from "../../wrappers/UseAuthentication";
import { useSupplierRetailer } from "../../wrappers/SupplierRetailerContext";
import { usePageAccess } from "../../wrappers/PageAccessContext";
const SignIn = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const { signIn } = useAuthentication();
  const { fetchUserAccess, resetContext: resetSupplierRetailerContext } = useSupplierRetailer();
  const { fetchPageAccess, resetContext: resetPageAccessContext } = usePageAccess();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setFormIsSubmitting(true);
    resetSupplierRetailerContext();
    resetPageAccessContext();

    try {
      const response = await axios.post(`${apiUrl}/login/authenticate`, { username, password });
      setFormIsSubmitting(false);

      if (response.status === 201) {
        const userid = response.data.data[0].userid;
        const fname = response.data.data[0].fname;

        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
        localStorage.setItem("userid", userid);
        localStorage.setItem("fname", fname);

        await fetchUserAccess(userid);
        await fetchPageAccess(userid);
        toast({
          title: response.data.message,
          description: "Looks good!",
          status: "success",
          duration: 500,
          isClosable: true,
        });
        signIn();
        navigate("/sales");
      } else {
        toast({
          title: response.data.message,
          description: response.data.description,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setFormIsSubmitting(false);
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      toast({
        title: "Sign in Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <FormControl maxW="400px" mx="auto" mt={8} p="4" borderWidth="1px" borderRadius="lg">
      <form onSubmit={handleSignIn}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Username</FormLabel>
            <Input type="text" placeholder="Enter your username" value={username} onChange={(e) => setUsername(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input type="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </FormControl>
          <Button type="submit" 
          bg={theme.colors.primary1} 
          _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} 
          color="white" 
          isLoading={formIsSubmitting} loadingText="Signing In">
            Sign in
          </Button>
          <Link color={theme.colors.secondary1} textAlign="center" fontSize="md" fontWeight={"bold"}>
            Forgot password?
          </Link>
        </Stack>
      </form>
    </FormControl>
  );
};

export default SignIn;
