import React, { useEffect, useState, useRef } from "react";
import { Box, Spinner, Heading,useTheme,  } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import ChartJS from "../../../tools/ChartSetup";
import gradient from "chartjs-plugin-gradient";

import { generateColorPalette } from "../../../tools/generateColorPalette";
const colorPalette = generateColorPalette("#000046", "#000046", 8);
//const colorPalette = generateColorPalette("#04aec1", "#58c087", 8);

const Chart5 = ({ supplier, retailer }) => {
  const theme = useTheme();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_sales/chart5`, {
          supplier,
          retailer,
          userid: 1,
        });
        const data = res.data.data;

        const chartData = {
          labels: data.map((item) => new Date(item.date).toLocaleDateString("en-US", { month: "short", year: "numeric" })),
          datasets: [
            {
              label: "Units",
              data: data.map((item) => item.value),
              backgroundColor: data.map((_, index) => {
                const ctx = chartRef.current?.ctx;
                if (ctx) {
                  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                  gradient.addColorStop(0, colorPalette[index % colorPalette.length]);
                  gradient.addColorStop(1, "#ffffff");
                  return gradient;
                }
                return colorPalette[index % colorPalette.length];
              }),
              borderColor: "rgba(219,219,219, 1)",
              borderWidth: 2,
            },
          ],
        };
        setChartData(chartData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  if (loading) return <Spinner />;

  return (
    <Box height="30vh">
      <Heading size="md" textAlign="center" mb={4}  bg={theme.colors.primary2} color={"white"}  p={1}>
        Monthly Units
      </Heading>
      {chartData && (
        <Bar
          ref={chartRef}
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false, // Hide the legend
              },
              datalabels: {
                display: true,
                align: "top",
                color: "#000",
                backgroundColor: "#fff",
                borderRadius: 3,
                padding: 3,
                formatter: function (value) {
                  if (typeof value === "number") {
                    return value.toLocaleString();
                  }
                  return value;
                },
              },
              filler: { propagate: false },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Month",
                },
                grid: {
                  display: false, // Remove vertical grid lines
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Units",
                },
                grid: {
                  display: false, // Remove vertical grid lines
                },
              },
            },
          }}
        />
      )}
    </Box>
  );
};

export default Chart5;
