import React, { useEffect, useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Heading, Spinner ,useTheme, } from "@chakra-ui/react";
import axios from "axios";

const Top10A = ({ supplier, retailer }) => {
  const theme = useTheme();
  const [data, setData] = useState({ thead: [], tbody: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_sales/top10_1`, {
          supplier,
          retailer,
          userid: 1,
        });
        setData(res.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Box >
      <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
        Top 10 Stores Highest Sales Value
      </Heading>
      <Table variant="striped" size="sm">
        <Thead>
          {data.thead.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row.map((col, colIndex) => (
                <Th key={colIndex} colSpan={col[1]?.value || 1}>
                  {Array.isArray(col) ? col[0] : col}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {data.tbody.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row.map((col, colIndex) => (
                <Td key={colIndex}>{Array.isArray(col) ? col[0] : col}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Top10A;
