import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Input, FormControl, FormLabel, Button,  useToast, Flex,IconButton, Select,  Grid } from "@chakra-ui/react";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import axios from "axios";

const AddSupplier = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const [name, setName] = useState("");
  const [supplierCode, setSupplierCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [sideNav, setSideNav] = useState(["Sales Reports", "Strike Rate Reports", "Month End Reports", "Costing Model"]);
  const [selectedsideNav, setSelectedsideNav] = useState([]);

  const [topNav, setTopNav] = useState(["PNP", "Checkers", "Dischem", "Makro", "Clicks", "Summary", "Data"]);
  const [selectedtopNav, setSelectedtopNav] = useState([]);

  const [dropdownValue, setDropdownValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedUserAccess, setSelectedUserAccess] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
    // https://spearbi.co.za/backend/reports/api/v1/admin/get-dd-users
    // http://localhost:3113/reports/api/v1/admin/get-dd-users
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-dd-users`, {});
        const { users, message, description } = response.data;
        toast({
          title: "drop downs loaded successfully",
          description: description,
          status: "success",
          duration: 500,
          isClosable: true,
        });
        setDropdownOptions(users);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast({
          title: "Failed to load users",
          description: "Technical error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchUsers();
  }, []);

  const handleNameChange = (e) => setName(e.target.value);
  const handleSupplierCodeChange = (e) => setSupplierCode(e.target.value);
  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleSideNavToggle = (item) => {
    if (selectedsideNav.includes(item)) {
      setSelectedsideNav(selectedsideNav.filter((c) => c !== item));
    } else {
      setSelectedsideNav([...selectedsideNav, item]);
    }
  };
  const handleTopNavToggle = (item) => {
    if (selectedtopNav.includes(item)) {
      setSelectedtopNav(selectedtopNav.filter((c) => c !== item));
    } else {
      setSelectedtopNav([...selectedtopNav, item]);
    }
  };
  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
    if (e.target.value == "") {
      return;
    }
    if (!selectedUserAccess.includes(e.target.value)) {
      setSelectedUserAccess([...selectedUserAccess, e.target.value]);
    }
    setDropdownValue("");
  };
  const handleRemoveDropdownItem = (item) => {
    setSelectedUserAccess(selectedUserAccess.filter((i) => i !== item));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
    // https://spearbi.co.za/backend/reports/api/v1/admin/
    // http://localhost:3113/reports/api/v1/admin/
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/ins-supplier`, {
        name,
        supplierCode,
        username,
        password,
        selectedsideNav,
        selectedtopNav,
        selectedUserAccess,
      });

      const { data, message, description } = response.data;
      if (response.status == 201) {
        toast({
          title: message,
          description: description,
          status: "success",
          duration: 1000,
          isClosable: true,
        });
        clearForm();
      } else {
        toast({
          title: message,
          description: description,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast({
        title: "Failed to load users",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setIsSubmitting(false);
  };

  const clearForm = async () => {
    setSelectedsideNav([]);
    setSelectedtopNav([]);
    setSelectedUserAccess([]);
    setDropdownValue("");
    setName("");
    setSupplierCode("");
    setUsername("");
    setPassword("");
    setIsSubmitting(false);
  };

  return (
    <Box overflowX="auto">
      <Flex justify="space-between" mb={4} align="center">
        <IconButton icon={<FaArrowLeft />} colorScheme="teal" onClick={handleBack} aria-label="Go back" />
      </Flex>
      <Box width="500px" margin="0 auto" textAlign="center" padding="4" boxShadow="md" borderRadius="md" backgroundColor="white">
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input type="text" value={name} onChange={handleNameChange} placeholder="ie: Herbal Horse" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Supplier Code</FormLabel>
            <Input type="text" value={supplierCode} onChange={handleSupplierCodeChange} placeholder="ie: HERBAL HORSE" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Username</FormLabel>
            <Input type="text" value={username === "" ? "" : username} onChange={handleUsernameChange} placeholder="ie: spearHerbal" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input type="text" value={password} onChange={handlePasswordChange} autoComplete="new-password" />
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Side Navigation Access</FormLabel>
            <Box>
              {sideNav.map((item) => (
                <Button variant={selectedsideNav.includes(item) ? "solid" : "outline"} colorScheme={selectedsideNav.includes(item) ? "green" : "gray"} mr={2} mb={2} onClick={() => handleSideNavToggle(item)}>
                  {item}
                </Button>
              ))}
            </Box>
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Retailer Access</FormLabel>
            <Box>
              {topNav.map((item) => (
                <Button variant={selectedtopNav.includes(item) ? "solid" : "outline"} colorScheme={selectedtopNav.includes(item) ? "green" : "gray"} mr={2} mb={2} onClick={() => handleTopNavToggle(item)}>
                  {item}
                </Button>
              ))}
            </Box>
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Users that have access to this supplier:</FormLabel>
            <Select value={dropdownValue} onChange={handleDropdownChange}>
              <option value="">Select Item</option>
              {dropdownOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mt="2">
            <Grid templateColumns="repeat(4, 1fr)" gap={0.2}>
              {selectedUserAccess.map((item) => (
                <Button size="xs" rightIcon={<FaTimes />} minWidth="120px" key={item} variant="solid" colorScheme="blue" mr={1} mb={2} pl={3} onClick={() => handleRemoveDropdownItem(item)}>
                  {item}
                </Button>
              ))}
            </Grid>
          </FormControl>

          <Box mt={4}>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AddSupplier;
