import React, { useEffect, useRef, useState } from "react";
import ChartJS from "../../../tools/ChartSetup";
import { Box, Heading, Spinner,useTheme,  } from "@chakra-ui/react";
import axios from "axios";
import { Bar } from "react-chartjs-2";

const Chart6 = ({ supplier, retailer }) => {
  const theme = useTheme();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_sales/chart6`, {
          supplier,
          retailer,
          userid: 1,
        });

        const result = res.data;

        if (!result || !result.data) {
          throw new Error("Invalid data format");
        }

        const data = result.data.map((item) => ({
          ...item,
          backgroundColor: item.value < 0 ? "rgba(221, 24, 24, 0.6)" : "rgba(15, 155, 15, 0.6)",
          borderColor: item.value < 0 ? "rgba(221, 24, 24, 1)" : "rgba(15, 155, 15, 1)",
        }));

        const chartData = {
          labels: data.map((item) =>
            new Date(item.date).toLocaleDateString("en-GB", {
              year: "numeric",
              month: "short",
            })
          ),
          datasets: [
            {
              label: "% vs LY",
              data: data.map((item) => item.value),
              backgroundColor: data.map((item) => item.backgroundColor),
              borderColor: data.map((item) => item.borderColor),
              borderWidth: 2,
              fill: true,
            },
          ],
        };

        setChartData(chartData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <Spinner/>;

  return (
    <Box height="30vh" maxHeight="30vh">
      <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"}  p={1}>
        YOY Growth Units
      </Heading>
      {chartData && (
        <Bar
          ref={chartRef}
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false, // Hide the legend
              },
              datalabels: {
                display: true,
                align: "top",
                color: "#000",
                backgroundColor: "#fff",
                borderRadius: 3,
                padding: 3,
                formatter: function (value) {
                  if (typeof value === "number") {
                    return value.toLocaleString();
                  }
                  return value;
                },
              },
              filler: { propagate: false },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Month",
                },
                grid: {
                  display: false, // Remove vertical grid lines
                },
              },
              y: {
                title: {
                  display: true,
                  text: "%",
                },
                grid: {
                  display: false, // Remove vertical grid lines
                },
              },
            },
          }}
        />
      )}
    </Box>
  );
};

export default Chart6;
