import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const SupplierRetailerContext = createContext();

export const useSupplierRetailer = () => useContext(SupplierRetailerContext);

export const SupplierRetailerProvider = ({ children }) => {
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [navBtns, setNavBtns] = useState([]);
  const [supplierSelect, setSupplierSelect] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUserAccess = async (userid) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/get--user-access`, { userid });
      const { navBtns, supplierSelect, userdefaults } = response.data.data[0];

      setNavBtns(navBtns);
      setSupplierSelect(supplierSelect);
      setSelectedRetailer(userdefaults.retailer);
      setSelectedSupplier(userdefaults.supplier);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetContext = () => {
    setNavBtns([]);
    setSupplierSelect([]);
    setSelectedRetailer("");
    setSelectedSupplier("");
    setLoading(false);
  };

  return (
    <SupplierRetailerContext.Provider
      value={{
        selectedRetailer,
        setSelectedRetailer,
        selectedSupplier,
        setSelectedSupplier,
        navBtns,
        supplierSelect,
        loading,
        fetchUserAccess,
        resetContext,
      }}
    >
      {children}
    </SupplierRetailerContext.Provider>
  );
};
